import WEBSITE from '@/utilities/constants/Website';
import dynamic from 'next/dynamic';
import { useWebsite } from '@/utilities/contexts/Website';

const Authors = dynamic(() => import('@/components/templates/Authors'));
const Author = dynamic(() => import('@/components/templates/Author'));
const Ebook = dynamic(() => import('@/components/templates/Ebook'));
const Ebooks = dynamic(() => import('@/components/templates/Ebooks'));
const Event = dynamic(() => import('@/components/templates/Event'));
const Events = dynamic(() => import('@/components/templates/Events'));
const Legal = dynamic(() => import('@/components/templates/Legal'));
const News = dynamic(() => import('@/components/templates/News'));
const Page = dynamic(() => import('@/components/templates/Page'));
const Partnership = dynamic(() => import('@/components/templates/Partnership'));
const CustomerStory = dynamic(
  () => import('@/components/templates/CustomerStory'),
);
const CustomerStories = dynamic(
  () => import('@/components/templates/CustomerStories/CustomerStories'),
);
const App = dynamic(() => import('@/components/templates/App'));
const Apps = dynamic(() => import('@/components/templates/Apps'));
const Checklists = dynamic(() => import('@/components/templates/Checklists'));
const Checklist = dynamic(() => import('@/components/templates/Checklist'));
const Topic = dynamic(() => import('@/components/templates/Topic'));
const Topics = dynamic(() => import('@/components/templates/Topics'));

interface TemplateProps {
  content: any;
  type: string;
}

const Template = (props: TemplateProps) => {
  const { content, type } = props;

  const website: string = useWebsite();

  if (WEBSITE[website].types.includes('ebook') && type === 'ebook') {
    return <Ebook content={content} />;
  } else if (WEBSITE[website].types.includes('ebooks') && type === 'ebooks') {
    return <Ebooks content={content} />;
  } else if (WEBSITE[website].types.includes('event') && type === 'event') {
    return <Event content={content} />;
  } else if (WEBSITE[website].types.includes('events') && type === 'events') {
    return <Events content={content} />;
  } else if (WEBSITE[website].types.includes('topic') && type === 'topic') {
    return <Topic content={content} />;
  } else if (WEBSITE[website].types.includes('topics') && type === 'topics') {
    return <Topics content={content} />;
  } else if (WEBSITE[website].types.includes('app') && type === 'app') {
    return <App content={content} />;
  } else if (WEBSITE[website].types.includes('apps') && type === 'apps') {
    return <Apps content={content} />;
  } else if (WEBSITE[website].types.includes('authors') && type === 'authors') {
    return <Authors content={content} />;
  } else if (WEBSITE[website].types.includes('author') && type === 'author') {
    return <Author content={content} />;
  } else if (
    WEBSITE[website].types.includes('checklist') &&
    type === 'checklist'
  ) {
    return <Checklist content={content} />;
  } else if (
    WEBSITE[website].types.includes('checklists') &&
    type === 'checklists'
  ) {
    return <Checklists content={content} />;
  } else if (WEBSITE[website].types.includes('legal') && type === 'legal') {
    return <Legal content={content} />;
  } else if (WEBSITE[website].types.includes('news') && type === 'news') {
    return <News content={content} />;
  } else if (
    WEBSITE[website].types.includes('news') &&
    type === 'partnership'
  ) {
    return <Partnership content={content} />;
  } else if (
    WEBSITE[website].types.includes('customer') &&
    type === 'customer'
  ) {
    return <CustomerStory content={content} />;
  } else if (
    WEBSITE[website].types.includes('customers') &&
    type === 'customers'
  ) {
    return <CustomerStories content={content} />;
  } else {
    return <Page content={content} />;
  }
};

export default Template;
